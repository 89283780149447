
function handleResize() {
    const windowWidth = window.innerWidth;

    const resolutionThreshold = 1199;

    let offCanvasTag = document.querySelector('.offcanvas.offcanvas-start');
    let offCanvasBackdropTag = document.querySelector('.offcanvas-backdrop');

    if (windowWidth > resolutionThreshold) {
        if (offCanvasTag && offCanvasBackdropTag) {
            if (offCanvasTag.classList.contains("show")) {
                offCanvasTag.classList.remove('show');
                offCanvasBackdropTag.classList.remove('show');
                body.style.overflow = '';
            }
        }
    }
}

document.addEventListener("DOMContentLoaded", handleResize);
window.addEventListener('resize', handleResize);

// Tener siempre la altura de la navbar en una custom property

function updateHeaderHeight() {
    const header = document.getElementById('header');
    if (header) {
        const headerHeight = header.getBoundingClientRect().height;
        document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
    }
}

const flagLogo = document.getElementById('flagLogo')

function updateFlagLogo() {
    const flagLogo = document.getElementById('flagLogo')
    const flagLogoWidth = flagLogo.getBoundingClientRect().width;
    document.documentElement.style.setProperty('--logo-width', `${flagLogoWidth}px`);
}

function updateHeroImg() {
    const heroImg =  document.querySelector('#inicio-section .margin-y-divided-content .section-content-element-image:first-child img')
    const heroImgWidth = heroImg.getBoundingClientRect().width;
    document.documentElement.style.setProperty('--heroImg-width', `${heroImgWidth}px`);
}

document.addEventListener("DOMContentLoaded", () => {
    setTimeout(updateHeaderHeight, 500);
    window.addEventListener('resize', updateHeaderHeight);
    setTimeout(updateFlagLogo, 500);
    window.addEventListener('resize', updateFlagLogo);
    setTimeout(updateHeroImg, 500);
})

if (controller === 'home') {

    const header = document.getElementById('header');
    const offcanvasToggler = document.getElementById('offcanvasToggler');
    const offCanvas = document.getElementById('bdNavbar');
    const headerLogo = document.getElementById('headerLogo');

    let scrollThreshold = 400; // Default value for large screens

    function updateScrollThreshold() {
        if (window.innerWidth < 992) {
            scrollThreshold = 75; // New value for small screens
        } else {
            scrollThreshold = 400; // Default value for large screens
        }
    }

    headerLogo.classList.add('custom-hide');

    function scrollNavbar() {
        if (document.documentElement.scrollTop >= scrollThreshold + header.getBoundingClientRect().height) {
            flagLogo.classList.add('flag-hide');
            headerLogo.classList.remove('d-none');
            headerLogo.classList.remove('custom-hide');
            offCanvas.classList.add('navbar-scrolled');
        } else {
            flagLogo.classList.remove('flag-hide');
            headerLogo.classList.add('custom-hide');
            headerLogo.classList.add('d-none');
            offCanvas.classList.remove('navbar-scrolled');
        }
        if (document.documentElement.scrollTop >= 0 + header.getBoundingClientRect().height) {
            header.classList.add('shadow');
            header.classList.add('navbar-scrolled');
        } else {
            header.classList.remove('shadow');
            header.classList.remove('navbar-scrolled');
        }
    }

    offcanvasToggler.onclick = function navbarMenu() {
        if (!offCanvas.classList.contains('show')) {
            offCanvas.classList.add('navbar-scrolled');
        } else {
            offCanvas.classList.remove('navbar-scrolled');
        }
    };

    // Update the threshold value on window resize and scroll
    window.addEventListener('resize', updateScrollThreshold);
    window.addEventListener('scroll', scrollNavbar);

    // Initial threshold update
    updateScrollThreshold();
} else {
    const header = document.getElementById('header');
    const offcanvasToggler = document.getElementById('offcanvasToggler');
    const offCanvas = document.getElementById('bdNavbar');
    // const headerLogo = document.getElementById('headerLogo');

    let scrollThreshold = 400; // Default value for large screens

    function updateScrollThreshold() {
        if (window.innerWidth < 992) {
            scrollThreshold = 150; // New value for small screens
        } else {
            scrollThreshold = 400; // Default value for large screens
        }
        console.log(scrollThreshold)
    }

    // headerLogo.classList.add('custom-hide');

    function scrollNavbar() {
        if (document.documentElement.scrollTop >= 0 + header.getBoundingClientRect().height) {
            header.classList.add('shadow');
            header.classList.add('navbar-scrolled');
            offCanvas.classList.add('navbar-scrolled');
        } else {
            header.classList.remove('shadow');
            header.classList.remove('navbar-scrolled');
            offCanvas.classList.remove('navbar-scrolled');
        }
    }

    offcanvasToggler.onclick = function navbarMenu() {
        if (!offCanvas.classList.contains('show')) {
            // header.classList.add('shadow')
            // header.classList.add('navbar-scrolled')
            offCanvas.classList.add('navbar-scrolled');
        } else {
            // header.classList.remove('shadow')
            // header.classList.remove('navbar-scrolled')
            offCanvas.classList.remove('navbar-scrolled');
        }
    };

    // Update the threshold value on window resize and scroll
    window.addEventListener('resize', updateScrollThreshold);
    window.addEventListener('scroll', scrollNavbar);

    // Initial threshold update
    updateScrollThreshold();
}