// Para empujar hacia arriba el footer, cuando el botón de whatsapp está abajo

function updateFooterMargin() {
  const button = document.getElementById('whatsapp-button');
  if (button) {
    const buttonHeight = button.getBoundingClientRect().height;
    document.documentElement.style.setProperty('--whatsapp-button-height', `${buttonHeight}px`);
  }
}

document.addEventListener("DOMContentLoaded", updateFooterMargin);
window.addEventListener('resize',updateFooterMargin);